import React from "react"
import Helmet from "react-helmet"
import Unsubscribe from "@tightrope/footerlinks/unsubscribe"
import HomepageLayout from "src/layouts/homepage"
import JSONData from "branding/site-metadata.json"

export default function UnsubscribePage() {
  return(
    <HomepageLayout>
        <Helmet data={JSONData}><title>Aioli Sous Chef - AI-Power Cooking Tips</title></Helmet>
    <Unsubscribe></Unsubscribe>
    </HomepageLayout>
  )
}
